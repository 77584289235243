import React from "react";
import { CoreFooter } from "../../components/Footer";

export default function Overview(props) {
  return (
    <div className="bg-white w-full h-full flex flex-col items-center overflow-y-auto">
      <div
        className="w-full min-h-[65%] bg-cover bg-center bg-no-repeat p-6 overflow-y-auto flex justify-center items-end text-white gap-8"
        style={{
          backgroundImage: `linear-gradient( hsla(202, 100%, 30%, 0.8), hsla(202, 100%, 35%, 0.8) ),
          url("https://www.urimat.com/fileadmin/_processed_/a/0/csm_service_wagen2a_d9b3a0219a.jpg")`,
        }}
      >
        <div className="w-1/3 h-2/3 font-bold text-3xl text-center">
          URIMAT service
        </div>
        <div className="w-1/3 h-2/3 text-3xl ">
          URIMAT waterless urinals offer the highest quality standards and
          above-average lifespan. They have also stood up to intensive use over
          the years. We guarantee the complete reliability of all URIMAT
          products.
        </div>
      </div>
      <div className="w-full flex flex-col items-center justify-center my-16">
        <div className="flex flex-col w-[60%] items-center  top-12 gap-16">
          <div className="flex justify-center px-6 gap-6 text-secondary text-3xl">
            We offer our customers in Switzerland the following services to
            ensure optimal operation of URIMAT waterless urinal systems.
          </div>
          <div className="flex gap-8 w-full">
            <div className="bg-white shadow-card w-1/2 rounded-tl-3xl rounded-br-3xl p-6 flex flex-col justify-betweenitems-center font-light gap-6">
              <h2 className="text-primary font-normal font-sans text-2xl">
                URIMAT
              </h2>
              <h2 className="text-primary font-light font-sans text-2xl">
                full-service subscription
              </h2>
              <p>
                With the URIMAT full-service subscription, we guarantee the
                timely replacement of MB-ActiveTraps, as well as thorough
                maintenance of the URIMAT systems including rinsing of drain
                pipes. As part of the URIMAT full-service subscription, our
                service technicians will carry out periodic, unsolicited service
                work on site over the course of the year, as agreed.
              </p>
              <p>
                The URIMAT full-service subscription includes the following
                services:
              </p>

              <ul className="text-txtTT flex flex-col gap-2 list-disc list-inside">
                <li>Getting there</li>
                <li>Time requirements for service work</li>
                <li>Cleaning the URIMAT systems</li>
                <li>Pipe rinsing</li>
                <li>
                  Replacement of odour trap inserts MB-ActiveTrap, including
                  materials
                </li>
                <li>Functional checks of URIMAT systems</li>
                <li>
                  Accessories, e.g. cleaning agents can be obtained directly by
                  the service team on site, with no postage costs
                </li>
              </ul>
            </div>
            <div className="bg-white shadow-card w-1/2 rounded-tl-3xl rounded-br-3xl p-6 flex flex-col justify-betweenitems-center font-light gap-6">
              <h2 className="text-primary font-normal font-sans text-2xl">
                URIMAT
              </h2>
              <h2 className="text-primary font-light font-sans text-2xl">
                supply agreement
              </h2>
              <p>
                URIMAT Schweiz AG offers the URIMAT supply agreement to enable
                you to focus fully on your day-to-day business. Without the need
                to worry about ordering URIMAT accessories, we will deliver a
                year’s supply of MB-ActiveTraps in a single annual delivery at
                special rates, unsolicited, on time and with no delivery costs.
              </p>
              <p>
                The URIMAT supply agreement includes the following services:
              </p>

              <ul className="text-txtTT flex flex-col gap-2 list-disc list-inside">
                <li>Year’s supply of MB-ActiveTraps </li>
                <li>Detailed instructions for self-replacement </li>
                <li>MB-ActiveTrap handle for removing used inserts </li>
                <li>Free delivery </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <CoreFooter />
    </div>
  );
}
