import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ItemCard } from "../components/Card";
import { CoreFooter } from "../components/Footer";

export default function Home(props) {
  const [currentSlide, setcurrentSlide] = useState(0);
  const [num, setNum] = useState(5);
  React.useEffect(() => {
    function handleResize() {
      setNum(Math.floor(window.innerWidth / "330"));
    }
    window.addEventListener("resize", handleResize);
  });
  const slides = [
    <ItemCard
      key="Urinals"
      header="Waterless urinals"
      description="The waterless urinal from URIMAT impresses with its modern design."
      imageUrl="https://www.urimat.com/fileadmin/user_upload/urimat_ch_urinale_teaser.png"
      url="/products/urinals"
      title="Urinals"
    />,
    <ItemCard
      key="Hand dryers"
      header="Hand dryers"
      description="Dry your hands in 9-11 seconds. Quiet, fast and environmentally friendly."
      imageUrl="https://www.urimat.com/fileadmin/teaser/startseite/teaser-bg-handdryer.png"
      url="/products/hand-dryers"
      title="Hand dryers"
    />,
    <ItemCard
      key="FX-M Unit"
      header="FX-M Washbasin Modular"
      imageUrl="https://www.urimat.com/fileadmin/produkte/fx-m/urimat_teaser_fx_m_wash_station.png"
      description="All-in-one modular washbasin unit, touchless usage and hygienic washing station"
      url="/products/urinals"
      title="FX-M Unit"
    />,
    <ItemCard
      key="ceramicc2-cs"
      header="URIMAT ceramicC2-CS"
      imageUrl="https://www.urimat.com/fileadmin/teaser/startseite/header-urimat-ceramic-c2-rs_blau_2019_teaser.png"
      description="Stylish 0-litre urinal, including automatic cleaning system"
      url="/products/urinals"
      title="ceramicc2-cs"
    />,
    <ItemCard
      key="cleanig"
      header="Biological Cleaner"
      imageUrl="https://www.urimat.com/fileadmin/teaser/startseite/mb-activecleaner_urimat_teaser.png"
      description="Dermatologically tested and rated very good ecological toilet cleaner."
      url="/products/urinals"
      title="cleanig"
    />,
    <ItemCard
      key="urimat connect"
      header="URIMAT Connect"
      imageUrl="https://www.urimat.com/fileadmin/produkte/connect/urimat_connect.png"
      description="Effective advertising - with static and digital advertising spaces (DOOH)."
      url="/products/urinals"
      title="urimat connect"
    />,
    <ItemCard
      key="partition walls"
      header="Urinal partition walls"
      imageUrl="https://www.urimat.com/fileadmin/produkte/trennwaende/header-trennwaende.png"
      description="Partition wall and design element all in one"
      url="/products/urinals"
      title="partition walls"
    />,
    <ItemCard
      key="Disinfection"
      header="Disinfection"
      imageUrl="https://www.urimat.com/fileadmin/user_upload/header-handhygiene.png"
      description="Disinfection and hand hygiene"
      url="/products/urinals"
      title="Disinfection"
    />,
  ];
  var indents = [];
  // for (var i = 0; i < Math.ceil(slides.length) / (num > 5) ? 5 : num; i++) {
  //   indents.push({
  //     item: (
  //       <div
  //         key={i}
  //         className={`rounded-full h-4 w-4  hover:cursor-pointer ${
  //           currentSlide === i ? "bg-primary" : "bg-gray-400"
  //         }`}
  //       />
  //     ),
  //   });
  // }
  console.log(Math.ceil(slides.length / num));
  return (
    <div className="bg-white w-full h-full flex flex-col items-center overflow-y-auto">
      <div
        className="w-full min-h-[65%] bg-cover bg-center bg-no-repeat p-6 overflow-y-auto flex justify-around items-center  bg-opacity-50 "
        style={{
          backgroundImage: `linear-gradient( hsla(202, 100%, 30%, 0.8), hsla(202, 100%, 35%, 0.8) ), 
          url("https://www.urimat.com/fileadmin/_processed_/c/2/csm_bergsee-header-1920x1080_834adf7769.jpg")`,
        }}
      >
        <div className="flex flex-col text-white">
          <span className="text-4xl font-bold mt-[15%]">
            URIMAT - Leader in waterless sanitary technology
          </span>
          <ul className="text-2xl pl-4">
            <li>No water costs</li>
            <li>No chemicals</li>
            <li>
              Waterless urinal is produced CO<sub>2</sub> neutral
            </li>
            <li>Touchless usage</li>
          </ul>
        </div>
      </div>
      <div className="w-full flex flex-col items-center justify-start">
        <div className="flex flex-col w-4/5 -mt-16 mb-12 gap-12 items-center">
          <div className="w-full flex flex-col items-center">
            <div className=" flex h-136 flex-nowrap w-full overflow-x-hidden gap-8 mb-8 items-top">
              {slides
                .slice(
                  currentSlide === 0 ? 0 : num > 5 ? 5 : num * currentSlide,
                  currentSlide === 0
                    ? num > 5
                      ? 5
                      : num
                    : num > 5
                    ? 5
                    : num * currentSlide + num > 5
                    ? 5
                    : num
                )
                .map((e, i) => (
                  <div className={`w-1/${num > 5 ? 5 : num}`}>{e}</div>
                ))}
            </div>
            {/* <div className="flex gap-8">
            {indents.map((element, i) => (
              <div
                onClick={() => {
                  setcurrentSlide(i);
                  console.log("currentSlide : ", i);
                  console.log("num : ", num);
                  console.log(
                    "Math.ceil(slides.length / num) : ",
                    Math.ceil(slides.length / num)
                  );
                }}
              >
                {element.item}
              </div>
            ))}
          </div> */}
            <div className="w-[70%] flex flex-col items-center gap-10">
              <div className="w-full flex gap-4 mt-12">
                <div className="w-full  text-primary font-extralight text-5xl">
                  URIMAT Worldwide
                </div>
                <div className="w-full text-xl font-light text-ellipsis gap-2">
                  URIMAT is a leading manufacturer of waterless urinals and
                  environmentally friendly products in public and semi-public
                  sanitary facilities.
                  <Link
                    to="/products/urinals"
                    className="mt-3 rounded-tr-3xl rounded-bl-3xl hover:cursor-pointer hover:scale-105 text-white font-thin text-xl flex justify-center items-center w-[60%] h-14 bg-primary"
                  >
                    Find Your Distributor
                  </Link>
                </div>
              </div>
              <Link className="w-full">
                <div
                  className=" aspect-[16/9] bg-cover bg-center bg-no-repeat"
                  style={{
                    backgroundSize: "50% 50%",
                    backgroundImage: `url("https://www.urimat.com/fileadmin/teaser/startseite/202003_worldmapas_01.png")`,
                  }}
                />
              </Link>
              <div className="w-full flex gap-6 mb-20">
                <div className="text-primary font-extralight text-3xl w-1/2">
                  The company that produces environmentally friendly, economical
                  solutions in the sanitary area
                </div>
                <div className="text-txtTT font-light text-xl w-1/2 flex flex-col gap-6">
                  ‘For more than 20 years the name URIMAT has stood for
                  outstanding quality, innovation and reliable service. Our
                  waterless urinal helps companies to reduce costs and make a
                  meaningful contribution to the environment. The environment,
                  our employees and reliable and fair partnerships are
                  particularly close to our heart.’
                  <div className="flex gap-6">
                    <div
                      className="w-32 h-32 aspect-[1/1] bg-cover bg-red-400 bg-no-repeat bg-center rounded-full"
                      style={{
                        backgroundImage: `url("https://www.urimat.com/fileadmin/_processed_/8/5/csm_ch.marcel-naepflin_cacd9ca8fd.png")`,
                      }}
                    />
                    <div className="w-full flex flex-col">
                      <div className="font-semibold">Marcel Näpflin</div>
                      Director Sales & Marketing
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex gap-6 text-center">
                <div className="flex flex-col text-4xl text-primary gap-2 items-center">
                  Marcel Näpflin
                  <div className="text-txtTT text-xl font-extralight gap-2">
                    <div className="text-2xl font-light">
                      Savings per waterless urinal
                    </div>
                    At least 100,000 litres of drinking water per urinal are
                    saved each year.
                  </div>
                </div>
                <div className="flex flex-col text-4xl text-primary gap-2 items-center">
                  Marcel Näpflin
                  <div className="text-txtTT text-xl font-extralight gap-2">
                    <div className="text-2xl font-light">
                      Savings per waterless urinal
                    </div>
                    At least 100,000 litres of drinking water per urinal are
                    saved each year.
                  </div>
                </div>
                <div className="flex flex-col text-4xl text-primary gap-2 items-center">
                  Marcel Näpflin
                  <div className="text-txtTT text-xl font-extralight gap-2">
                    <div className="text-2xl font-light">
                      Savings per waterless urinal
                    </div>
                    At least 100,000 litres of drinking water per urinal are
                    saved each year.
                  </div>
                </div>
              </div>
              <Link
                to="/products/urinals"
                className="mt-3 rounded-tr-3xl rounded-bl-3xl hover:cursor-pointer hover:scale-105 text-white font-thin text-xl flex justify-center items-center w-[20%] h-14 bg-primary"
              >
                Find Your Distributor
              </Link>
              <div className="flex gap-6 pb-20">
                <div className="bg-white text-txtTT shadow-card w-1/3 aspect-3/1 rounded-tl-3xl items-center gap-10 rounded-br-3xl p-4 flex flex-col">
                  <div className="text-2xl font-light">
                    Efficient green solution
                  </div>
                  <div className="text-xl font-extralight">
                    URIMAT is part of the Solar Impulse Foundation 1000
                    solutions that are clean, profitable & protect the
                    environment.
                  </div>
                  <div
                    className=" bg-no-repeat bg-contain bg-center aspect-[1/1] w-[60%]"
                    style={{
                      backgroundImage: `url(https://www.urimat.com/fileadmin/_processed_/e/a/csm_sif_label_logo_institutional_2020_rvb_a6a7b5af58.png)`,
                    }}
                  />
                </div>
                <div className="bg-white text-txtTT shadow-card w-1/3 aspect-3/1 rounded-tl-3xl items-center gap-10 rounded-br-3xl p-4 flex flex-col">
                  <div className="text-2xl font-light">
                    Efficient green solution
                  </div>
                  <div className="text-xl font-extralight">
                    URIMAT is part of the Solar Impulse Foundation 1000
                    solutions that are clean, profitable & protect the
                    environment.
                  </div>
                  <div
                    className=" bg-no-repeat bg-contain bg-center aspect-[1/1] w-[60%]"
                    style={{
                      backgroundImage: `url(https://www.urimat.com/fileadmin/_processed_/5/c/csm_urimat_un_sdg_goals_f6bd7c598d.png)`,
                    }}
                  />
                </div>
                <div className="bg-white text-txtTT shadow-card w-1/3 aspect-3/1 rounded-tl-3xl items-center gap-10 rounded-br-3xl p-4 flex flex-col">
                  <div className="text-2xl font-light">
                    Efficient green solution
                  </div>
                  <div className="text-xl font-extralight">
                    URIMAT is part of the Solar Impulse Foundation 1000
                    solutions that are clean, profitable & protect the
                    environment.
                  </div>
                  <div
                    className=" bg-no-repeat bg-contain bg-center aspect-[1/1] w-[60%]"
                    style={{
                      backgroundImage: `url(https://www.urimat.com/fileadmin/_processed_/9/0/csm_u.s._green_building_council_logo.svg_0b9e58e153.png)`,
                    }}
                  />
                </div>
              </div>

              <div className="text-txtTT flex flex-col gap-3 text-3xl w-full">
                Why waterless urinals?
              </div>
              <div className="flex w-full gap-8 items-start">
                <div className="flex flex-col w-1/2">
                  <ul className="text-txtTT flex flex-col gap-3 list-disc">
                    <li>
                      Ceramic, stainless steel or plastic urinals have become
                      indispensable in public and semi-public men's toilets.
                    </li>
                    <li>
                      Conventional urinals and hybrid urinals require 0.5 to 4
                      litres of water per flush, waterless urinals (also known
                      as dry urinals) function entirely without water or
                      flushing devices. Thus, waterless urinals save precious
                      drinking water and costs, a big benefit in times of rising
                      water costs and water shortages.
                    </li>
                    <li>
                      The urinal bowls have a smooth surface from which the
                      urine flows into a siphon with membrane and biodegradable
                      odour stone. This serves as an odour trap and is the most
                      important component in waterless operation. The rubber
                      membrane of the trap opens when urine enters and closes
                      again airtight when there is no urine. This means that
                      there is no odour nuisance.
                    </li>
                  </ul>
                </div>
                <div
                  className=" bg-no-repeat bg-contain bg-center aspect-[10/7] w-1/2 "
                  style={{
                    backgroundImage: `url(https://www.urimat.com/fileadmin/produkte/urinale/header-urimat-uebersicht.png)`,
                  }}
                />
              </div>
              <div className="text-txtTT flex flex-col gap-3 text-3xl w-full">
                Why waterless urinals?
              </div>
              <div className="flex w-full gap-8 items-start">
                <ul className="text-txtTT flex flex-col gap-3 list-disc">
                  <li>
                    
                    No fresh water is used, which protects the environment and
                    reduces the amount of CO2 produced in the production of
                    drinking water.
                  </li>
                  <li>
                    {`The costs for fresh water and for waste water are saved altogether (depending on the object, water price and frequency of use, an ROI of < 2 years can be achieved, and after that one makes "profit")`}
                  </li>
                  <li>
                    The care and maintenance intensity is significantly lower
                    compared to the flush urinal.
                  </li>
                  <li>
                    Follow-up costs, e.g. due to repairs of the electronic
                    urinal flush systems, are lower, as no expensive urinal
                    controls are required
                  </li>
                  <li>
                    Lower investment costs for new buildings, because there is
                    no need to run cold water supplies to the urinals as there
                    is no flushing cistern
                  </li>
                  <li>
                    Since no connection to the drinking water system is
                    required, a waterless urinal can be installed with very
                    little effort
                  </li>
                  <li>
                    Sewer waste gases cannot penetrate the membrane odour trap
                    meaning that a pleasant ambience in the washroom is
                    maintained
                  </li>
                  <li>
                    Waterless urinals have no flushing rim or other joints where
                    bacteria could settle and are therefore hygienically perfect
                  </li>
                  <li>
                    The entire operation is completely touch-free, as no button
                    has to be pressed to trigger flushing
                  </li>
                  <li>
                    No urine scale (which builds up when water from the flush
                    reacts with urine) is formed because the water is missing,
                    and thus biological sanitary cleaners are completely
                    sufficient for cleaning
                  </li>
                  <li>
                    The "pure" urine can be collected without dilution and
                    contamination and used as fertiliser
                  </li>
                  <li>
                    Last but not least, waterless urinals also reduce CO2
                    emissions and improve your ecological footprint
                  </li>
                </ul>
              </div>

              <Link
                to="/about-us"
                className="mt-3 rounded-tr-3xl rounded-bl-3xl hover:cursor-pointer hover:scale-105 text-white font-thin text-xl flex justify-center items-center w-[20%] h-14 bg-primary"
              >
                More About Us
              </Link>
            </div>
          </div>
        </div>
      </div>
      <CoreFooter />
    </div>
  );
}
