import React from "react";
import { CoreFooter } from "../../components/Footer";

export default function FAQ(props) {
  return (
    <div className="bg-white w-full h-full flex flex-col items-center pt-16 overflow-y-auto">
      <div className="flex flex-col w-[60%] items-center gap-16 my-16 pt-8">
        <div className="w-full text-primary text-5xl font-light">
          Frequently Asked Questions (FAQ)
        </div>
        <div className="flex flex-col gap-8 w-full">
          <div className="w-full flex flex-col gap-3">
            <div className="flex w-full gap-8 border-t-1 border-gray-300 py-8">
              <div className="w-11/12 gap-4 flex flex-col">
                <div className="text-primary text-4xl font-light">
                  Urinals & Odour traps with membrane valve
                </div>
              </div>
              <div
                className="w-1/12 aspect-[9/16] bg-no-repeat bg-contain bg-center"
                style={{
                  backgroundImage:
                    "url(https://www.urimat.com/fileadmin/_processed_/4/0/csm_wasserloses_urinal_ecovideo_urimat_0c273f3e0d.png)",
                }}
              />
            </div>
            <div className="font-light text-txtTT text-xl">
              What is the lifetime of the MB-ActiveTrap?
            </div>
            <div className="font-extralight">
              The service life of the trap of waterless urinals is in average
              7000-8000 uses. After that, the cleaning cube is completely
              dissolved and the red visual indicator of the trap is visible. The
              urinal trap must then be replaced.
            </div>
            <div className="font-light text-txtTT text-xl">
              How can I replace the MB-ActiveTrap?
            </div>
            <div className="font-extralight">
              The trap of the waterless urinal can be easily replaced. Please
              watch the replacement instruction video:
              <a href="/services/instruction-videos" className="text-primary">
                Trap-Replacement
              </a>
            </div>
            <div className="font-light text-txtTT text-xl">
              Is there urine stone build-up in the wasterwater pipe?
            </div>
            <div className="font-extralight">
              The service life of the trap of waterless urinals is in average
              7000-8000 uses. After that, the cleaning cube is completely
              dissolved and the red visual indicator of the trap is visible. The
              urinal trap must then be replaced.
            </div>
            <div className="font-light text-txtTT text-xl">
              Do waterless urinals smell?
            </div>
            <div className="font-extralight">
              No, the waterless urinal itself does not smell.{" "}
              <span className="font-normal">
                There are five main reasons for odours in restrooms:
              </span>
              <ul className="text-txtTT flex flex-col gap-3 list-decimal list-inside p-4">
                <li>Environmentally friendly</li>
                <li>Cost-effective</li>
                <li>Quick and efficient</li>
                <li>Simple installation</li>
                <li>Robust & durable</li>
              </ul>
            </div>
            <div className="font-light text-txtTT text-xl">
              How does a waterless urinal work?
            </div>
            <div className="font-extralight">
              The technology and functionality is explained on the following
              link:
              <a href="/products/urinals" className="text-primary">
                Waterless urinal technology
              </a>
            </div>
            <div className="font-light text-txtTT text-xl">
              Are waterless urinals hygienic?
            </div>
            <div className="font-extralight">
              Waterless urinals improve hygiene in the washroom. Due to the
              absence of water, there are fewer bacteria that can develop.
              Studies have shown that there are fewer colony-forming units in
              waterless urinals compared to flush urinals.
            </div>
            <div className="font-light text-txtTT text-xl">
              How much cleaning/maintenance do waterless urinals require
              compared to water-flushed urinals?
            </div>
            <div className="font-extralight">
              Waterless urinals are much easier and faster to clean and maintain
              compared to conventional water-flushed urinals, as there are no
              edges and flushing rims where bacteria and limescale can build up.
              As waterless urinals are completely touch-free, there are no
              actuator plates or electronic sensors to clean or maintain.
            </div>
            <div className="font-light text-txtTT text-xl">
              What cost savings do I have thanks to waterless urinals?
            </div>
            <div className="font-extralight">
              Since waterless urinals do not require expensive flushing
              electronics/sensor technology compared to water-flushed urinals,
              the investment costs are lower. In addition, you save water and
              wastewater costs when operating waterless urinals.
              <a href="/services/savings-1" className="text-primary">
                You can calculate these cost savings online.
              </a>
            </div>
            <div className="font-light text-txtTT text-xl">
              How long does it take to install waterless urinals?
            </div>
            <div className="font-extralight">
              Waterless urinals are installed in less than 20 minutes. That's
              less than half the time it takes to install traditional
              water-flushed urinals. Waterless urinals are easy to install.
              Since no flush sensors are needed, there is no need for
              electricity, except for the models with LCD screen or
              self-cleaning function.
            </div>
            <div className="font-light text-txtTT text-xl">
              Do waterless urinals reduce running costs?
            </div>
            <div className="font-extralight">
              Waterless urinals drastically reduce your water bill. Depending on
              water costs, a waterless urinal pays for itself within 10-18
              months. You can find examples under the heading
              <a href="/about-us/references" className="text-primary">
                References
              </a>
            </div>
          </div>
          <div className="w-full flex flex-col gap-3">
            <div className="flex w-full gap-8 border-t-1 border-gray-300 py-8">
              <div className="w-11/12 gap-4 flex flex-col">
                <div className="text-primary text-4xl font-light">
                  Biological Sanitary Cleaner
                </div>
              </div>
              <div
                className="w-1/12 aspect-[9/16] bg-no-repeat bg-contain bg-center"
                style={{
                  backgroundImage:
                    "url(https://www.urimat.com/fileadmin/produkte/reinigungsmittel/flasche_1l_u_spruehflasche.png)",
                }}
              />
            </div>
            <div className="font-light text-txtTT text-xl">
              Is MB-ActiveCleaner ecological?
            </div>
            <div className="font-extralight">
              MB-ActiveCleaner is label-free and enables urinals to be cleaned
              without the use of hazardous and harmful chemicals.
            </div>
            <div className="font-light text-txtTT text-xl">
              Does MB-ActiveCleaner eliminate bad odours?
            </div>
            <div className="font-extralight">
              MB-ActiveCleaner removes bad odors that arise when organic
              substances are broken down. It is particularly suitable for
              maintenance cleaning and odor removal in heavily used sanitary
              facilities.
            </div>
            <div className="font-light text-txtTT text-xl">
              Are biological cleaners more expensive?
            </div>
            <div className="font-extralight">
              MB-ActiveCleaner offers a very good price-performance ratio, is
              highly concentrated and extremely economical. The biological
              sanitary cleaner is mostly cheaper than conventional sanitary
              cleaners.
            </div>
            <div className="font-light text-txtTT text-xl">
              Is MB-ActiveCleaner skin-friendly?
            </div>
            <div className="font-extralight">
              MB-ActiveCleaner has been dermatologically tested for skin
              compatibility and rated as very good.
            </div>
          </div>
          <div className="w-full flex flex-col gap-3">
            <div className="flex w-full gap-8 border-t-1 border-gray-300 py-8">
              <div className="w-11/12 gap-4 flex flex-col">
                <div className="text-primary text-4xl font-light">
                  Hand Dryer
                </div>
              </div>
              <div
                className="w-1/12 aspect-[9/16] bg-no-repeat bg-contain bg-center"
                style={{
                  backgroundImage:
                    "url(https://www.urimat.com/fileadmin/produkte/handdryer/handdryer-favorit-weiss-seite.png)",
                }}
              />
            </div>
            <div className="font-light text-txtTT text-xl">
              How high is the energy requirement for hand dryers?
            </div>
            <div className="font-extralight">
              The energy requirement is up to a hundred times lower compared to
              paper and cloth towel dispensers.
            </div>
            <div className="font-light text-txtTT text-xl">
              Are hand dryers hygienic?
            </div>
            <div className="font-extralight">
              Thanks to touch-free and sensor-controlled use, all our hand
              dryers are hygienic and safe for your health. For maximum hygiene,
              all URIMAT hand dryers are equipped as standard with replaceable
              HEPA filters that remove 99.9% of all dirt particles and bacteria
              from the air in the washroom before they come into contact with
              your hands before they come into contact with the hands.
            </div>
            <div className="font-light text-txtTT text-xl">
              Are hand dryers environmentally friendly?
            </div>
            <div className="font-extralight">
              Our hand dryer not only protects the environment, but also helps
              to save costs. No need to store or dispose of paper or cloth
              towels.
            </div>
            <div className="font-light text-txtTT text-xl">
              What is the service life of URIMAT hand dryers?
            </div>
            <div className="font-extralight">
              With the brushless electric motor there are no brushes (sliding
              contacts), so there is no mechanical abrasion. This means that the
              motors of all our hand dryer models last much longer.
            </div>
          </div>
        </div>
      </div>
      <CoreFooter />
    </div>
  );
}
