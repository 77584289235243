import React from "react";
import { ItemCard2 } from "../../components/Card";
import { CoreFooter } from "../../components/Footer";

export default function Urinals(props) {
  return (
    <div className="bg-white w-full h-full flex flex-col items-center overflow-y-auto">
      <div
        className="w-full min-h-[65%] bg-gray-200 bg-contain bg-left-top bg-no-repeat p-6 overflow-y-auto flex justify-around items-center"
        style={{
          backgroundImage: `url("https://www.urimat.com/fileadmin/user_upload/header-urimat-uebersicht_2020.png")`,
        }}
      >
        <div className="w-2/6"></div>
        <div className="w-2/6 flex flex-col gap-4">
          <div className="text-primary text-5xl font-bold">
            Waterless urinals
          </div>
          <div className="text-3xl text-txtSecondary font-extralight">
            The largest range of waterless urinals made from robust high-tech
            plastic and high-quality sanitary ceramics in a top design.
          </div>
          <ul className="text-txtTT flex flex-col gap-3 list-disc list-inside">
            <li>No water consumption</li>
            <li>No chemicals </li>
            <li>Basin production is CO2-neutral </li>
            <li>Touchfree operation</li>
          </ul>
        </div>
      </div>
      <div className="mb-16 -mt-16 flex flex-col w-4/5 -top-32 gap-12 items-center justify-center">
        <div className="w-full flex flex-col items-center gap-12">
          <div className="flex justify-center px-6">
            <div className="w-2/9 h-60 rounded-tl-3xl rounded-br-3xl p-6 flex flex-col items-center justify-center bg-primary ">
              <ul className="flex flex-col gap-3 text-white text-opacity-70 italic justify-center">
                <li>No water consumption</li>
                <li>No chemicals </li>
                <li>Basin production is CO2-neutral </li>
                <li>Touchfree operation</li>
              </ul>
            </div>
            <div className="flex flex-wrap row-span-3 gap-8 w-7/9 pl-8">
              <ItemCard2
                key="URIMAT eco"
                header="URIMAT eco"
                description="Hightech-plastic waterless urinal"
                imageUrl="https://www.urimat.com/fileadmin/_processed_/0/1/csm_urimat-ecoplus-klein_895f6a8b49.png"
                url="/products/urinals"
                title="Urinals"
              />
              <ItemCard2
                key="URIMAT ecoplus"
                header="URIMAT ecoplus"
                description="Hightech-plastic waterless urinal with advertising display"
                imageUrl="https://www.urimat.com/fileadmin/_processed_/0/1/csm_urimat-ecoplus-klein_895f6a8b49.png"
                url="/products/urinals"
                title="Urinals"
              />
              <ItemCard2
                key="URIMAT ecovideo"
                header="URIMAT ecovideo"
                description="Hightech-plastic waterless urinal with LCD video display"
                imageUrl="https://www.urimat.com/fileadmin/_processed_/0/1/csm_urimat-ecoplus-klein_895f6a8b49.png"
                url="/products/urinals"
                title="Urinals"
              />
              <ItemCard2
                key="URIMAT ceramicC2"
                header="URIMAT ceramicC2"
                description="Small waterless urinals made from non-porous high-tech ceramics"
                imageUrl="https://www.urimat.com/fileadmin/_processed_/0/1/csm_urimat-ecoplus-klein_895f6a8b49.png"
                url="/products/urinals"
                title="Urinals"
              />
              <ItemCard2
                key="URIMAT ceramicC2-CS"
                header="URIMAT ceramicC2-CS"
                description="Automatic, self-cleaning waterless ceramic urinal"
                imageUrl="https://www.urimat.com/fileadmin/_processed_/0/1/csm_urimat-ecoplus-klein_895f6a8b49.png"
                url="/products/urinals"
                title="Urinals"
              />
              <ItemCard2
                key="URIMAT ceramic20"
                header="URIMAT ceramic20"
                description="Stylish waterless urinals made from non-porous, polished ceramics."
                imageUrl="https://www.urimat.com/fileadmin/_processed_/0/1/csm_urimat-ecoplus-klein_895f6a8b49.png"
                url="/products/urinals"
                title="Urinals"
              />
              <ItemCard2
                key="URIMAT inox"
                header="URIMAT inox"
                description="Robust and low-maintenance"
                imageUrl="https://www.urimat.com/fileadmin/_processed_/0/1/csm_urimat-ecoplus-klein_895f6a8b49.png"
                url="/products/urinals"
                title="Urinals"
              />
              <ItemCard2
                key="URIMAT colouredurinals"
                header="URIMAT colouredurinals"
                description="Various designs and colors available for ceramic urinals on request"
                imageUrl="https://www.urimat.com/fileadmin/_processed_/0/1/csm_urimat-ecoplus-klein_895f6a8b49.png"
                url="/products/urinals"
                title="Urinals"
              />
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <div className="text-primary text-3xl font-semibold ">
              The technology of waterless urinals
            </div>
            <div className="text-1xl text-txtTT flex flex-col gap-4">
              <div>There are two types of waterless urinal technology:</div>
              <ul className="w-full list-decimal font-semibold px-10">
                <li>
                  Oil or chemical cartridges -
                  <span className="font-light">
                    urine passes through a oil or chemical-based barrier which
                    is then supposed to form a seal which reduces odours. Using
                    this system requires expensive cartridges to be replaced
                    every few months, and the maintenance costs are high,
                    particularly since the oil seal can easily be accidentally
                    broken, for example by a cleaner washing a bucket of water
                    down the urinal.
                  </span>
                </li>
                <li>
                  URIMAT's patented ActiveTrap or so called membrane trap -
                  <span className="font-light">
                    The membrane trap technology / membrane valve has proven its
                    performace for more than 10 years and is the newest
                    waterless urinal technology. It is much more effective at
                    preventing odours and hygiene issues as well as being
                    significantly cheaper and easier to maintain.
                  </span>
                </li>
              </ul>
              <div>
                The patented and exchangeable ActiveTrap used in the URIMAT
                Urinals collects the urine and discharges it (without using
                water) into the drainage system. No pollutant oils or chemicals
                are used and no odours can develop.
              </div>
            </div>
          </div>
          <div className="text-primary text-3xl font-semibold ">
            How does a waterless urinal work?
          </div>
          <div className="flex w-full gap-8">
            <div className="flex flex-col w-1/2">
              <ul className="text-txtTT flex flex-col gap-3 list-disc">
                <li>
                  Collected urine is channelled via a funnel into the odour trap
                  and runs through the vertically positioned membrane into the
                  sewage system.
                </li>
                <li>
                  As soon as urine stops flowing, the membrane closes and in
                  this way prevents gases from the sewage system from escaping.
                </li>
                <li>
                  The integrated MB-ActiveCube improves hygiene, reduces
                  deposits and prevents the inside of the odour trap and the
                  waste pipe becoming encrusted with organic matter, urine
                  deposits and fats.
                </li>
              </ul>
            </div>

            <div className="bg-white shadow-card w-1/2 aspect-3/1 rounded-tl-3xl rounded-br-3xl p-6 flex flex-col items-center">
              <ul className="flex flex-col gap-6 list-inside list-disc">
                <li>
                  After the MB-ActiveCube has dissolved, the red surface beneath
                  the transparent cover signals that the odour trap needs
                  replacing.
                </li>
                <li>
                  The vertical membrane technology guarantees that odours are
                  completely sealed away and can withstand both negative and
                  positive pressures, so it is impossible for gases from the
                  sewage system to escape.
                </li>
              </ul>
              <div
                className=" bg-no-repeat bg-contain bg-center aspect-[1/1] w-[100%]"
                style={{
                  backgroundImage: `url(https://www.urimat.com/fileadmin/produkte/activetrap/trap_ansicht_funktion-transparent.png)`,
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <CoreFooter />
    </div>
  );
}
