import { Input } from "antd";
import React, { useState } from "react";
import { CoreFooter } from "../../components/Footer";
import { CheckOutlined } from "@ant-design/icons";

export default function Savings(props) {
  const [costs, setCosts] = useState(0);
  const [waterSavings, setWaterSavings] = useState(0);
  const [coSavings, setCoSavings] = useState(0);
  const [sewageCost, setSewageCost] = useState(0);
  const [maleNumber, setMaleNumber] = useState(0);
  const [flushPerPerson, setFlushPerPerson] = useState(0);
  const [waterFlow, setWaterFlow] = useState(0);
  const [openDay, setOpenDay] = useState(0);
  const calculateSavings = () => {
    const cost =
      0.001 * sewageCost * maleNumber * flushPerPerson * waterFlow * openDay;
    setCosts(cost);
    const waterSaving =
      sewageCost * maleNumber * flushPerPerson * waterFlow * openDay;
    setCosts(cost);
    setWaterSavings(waterSaving);
    //CO calculate iig ni hunees ni asuuj hiih?

    console.log(cost);
  };
  return (
    <div className="bg-white w-full h-full flex flex-col items-center pt-16 overflow-y-auto">
      <div className="flex flex-col w-[60%] gap-12 my-16 pt-8">
        <div className="w-full text-primary text-5xl font-light">Savings</div>
        <div className="font-light text-txtTT text-2xl">
          Calculate your costs for your project regarding water saving and cost
          saving when installing waterless urinals
        </div>
        <div className="flex w-full gap-12">
          <div className="w-1/2 flex flex-col gap-8">
            <div className="flex w-full justify-between">
              <div className="text-txtTT font-normal ">
                Water and sewage costs
                <span className="font-extralight"> [per m3]</span>:
              </div>
              <Input
                type="number"
                onChange={(e) => {
                  setSewageCost(e.target.value);
                }}
                onPressEnter={calculateSavings}
                suffix={<CheckOutlined className="" />}
                className="w-[20%] min-w-24 h-12"
              />
            </div>
            <div className="flex w-full justify-between">
              <div className="text-txtTT font-normal ">
                Number of male employees / visitor:
              </div>
              <Input
                type="number"
                onChange={(e) => {
                  setMaleNumber(e.target.value);
                }}
                onPressEnter={calculateSavings}
                suffix={<CheckOutlined className="" />}
                className="w-[20%] min-w-24 h-12"
              />
            </div>
            <div className="flex w-full justify-between">
              <div className="text-txtTT font-normal ">
                Flushing processes per employee / visitor
                <span className="font-extralight"> [uses/day]</span>:
                <div className="font-extralight text-sm">
                  Each male employee uses the urinal Ø 4 x per day during
                  working hours
                </div>
              </div>
              <Input
                type="number"
                onChange={(e) => {
                  setFlushPerPerson(e.target.value);
                }}
                onPressEnter={calculateSavings}
                suffix={<CheckOutlined className="" />}
                className="w-[20%] min-w-24 h-12"
              />
            </div>
            <div className="flex w-full justify-between">
              <div className="text-txtTT font-normal ">
                Water flow
                <span className="font-extralight"> [litres/flush]</span>
                <div className="font-extralight text-sm">
                  (Ø 3 litres of drinking water are wasted with every urinal
                  flush)
                </div>
              </div>
              <Input
                type="number"
                onChange={(e) => {
                  setWaterFlow(e.target.value);
                }}
                onPressEnter={calculateSavings}
                suffix={<CheckOutlined className="" />}
                className="w-[20%] min-w-24 h-12"
              />
            </div>
            <div className="flex w-full justify-between">
              <div className="text-txtTT font-normal ">
                Opening days per year
              </div>
              <Input
                type="number"
                onChange={(e) => {
                  setOpenDay(e.target.value);
                }}
                onPressEnter={calculateSavings}
                suffix={<CheckOutlined className="" />}
                className="w-[20%] min-w-24 h-12"
              />
            </div>
          </div>
          <div className="w-1/2 flex flex-col gap-8">
            end ter sonin bar ni bna
          </div>
        </div>
        <div className="w-full border-t-1 border-gray-300 pt-8 flex flex-col gap-8">
          <div className="w-full text-primary text-3xl font-light">
            Potential savings
          </div>
          <div className="w-1/2 flex flex-col gap-8">
            <div className="w-full flex justify-between">
              <div className="text-txtTT font-normal ">
                Your water costs per year:
              </div>
              <div className="text-txtTT font-normal ">{costs}</div>
            </div>
            <div className="w-full flex justify-between">
              <div className="text-txtTT font-normal ">
                Your water savings per year:
              </div>
              <div className="text-txtTT font-normal ">{waterSavings}</div>
            </div>
            <div className="w-full flex justify-between">
              <div className="text-txtTT font-normal ">
                Your CO2 savings per year:
              </div>
              <div className="text-txtTT font-normal ">{coSavings}</div>
            </div>
          </div>
        </div>
      </div>
      <CoreFooter />
    </div>
  );
}
