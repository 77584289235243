import React from "react";
import { ItemCard4 } from "../../components/Card";
import { CoreFooter } from "../../components/Footer";
import { Link } from "react-router-dom";
export default function BiologicalCleaner(props) {
  return (
    <div className="bg-white w-full h-full flex flex-col items-center overflow-y-auto">
      <div
        className="w-full min-h-[65%] bg-gray-200 bg-contain bg-left bg-no-repeat p-6 overflow-y-auto flex justify-around items-center"
        style={{
          backgroundImage: `url("https://www.urimat.com/fileadmin/user_upload/header-handdryer2.png")`,
        }}
      >
        <div className="w-2/6"></div>
        <div className="w-2/6 flex flex-col gap-4">
          <div className="text-primary text-5xl font-bold">
            Biological Cleaner
          </div>
          <div className="text-3xl text-txtSecondary font-extralight">
            The dermatologically tested sanitary cleaner which has been rated as
            ‘very good’ prevents unpleasant odours due to the decomposition of
            organic matter.
          </div>
          <ul className="text-txtTT flex flex-col gap-3 list-disc list-inside">
            <li>pH-neutral</li>
            <li>Biological</li>
            <li>Sustainable</li>
          </ul>
        </div>
      </div>
      <div className="my-16 flex flex-col justify-center w-[60%] items-center gap-16">
        <div className="flex justify-center px-6 gap-6">
          <div className="text-txtTT font-light w-1/3">
            <div className=" font-normal">
              pH-neutral, biological and sustainable
            </div>
            The dermatologically tested MB-ActiveCleaner which has been rated as
            ‘very good’ prevents unpleasant odours due to the decomposition of
            organic matter. It is particularly suitable for maintenance cleaning
            and odour elimination in heavily frequented sanitary areas.
          </div>
          <div className="text-txtTT font-light w-1/3">
            <div className=" font-normal">Lasting, pleasant freshness</div>
            The micro-organisms penetrate deep into the pores (gaps) and break
            down the odour-forming residues deposited there. Our sanitary
            cleaner ensures lasting, pleasant freshness and prevents the
            development of new odours when used daily.
          </div>
          <div className="text-txtTT font-light w-1/3">
            <div className=" font-normal">Scope of Application</div>
            <li>Toilets and sanitary facilities</li>
            <li>Washrooms and shower rooms</li>
            <li>Building façades, throughways and stairwells</li>
            <li>Rubbish containers and rubbish collection stations</li>
            <li>Floors, carpeting, etc.</li>
          </div>
        </div>
        <div className="flex row-span-4 gap-8">
          <ItemCard4
            key="MB-ActiveCleaner 1-litre concentrate"
            header="MB-ActiveCleaner 1-litre concentrate"
            imageAspect="9/16"
            description={
              <div className="h-full flex flex-col justify-between">
                pH-neutral, biological and sustainable
                <div>
                  <div>Item no.: 80.001</div>
                </div>
              </div>
            }
            imageUrl="https://www.urimat.com/fileadmin/_processed_/a/3/csm_flasche_1l_efb79c60bf.png"
            url="/products/urinals"
            title="MB-ActiveTrap eco"
          />
          <ItemCard4
            key="MB-ActiveCleaner combo pack"
            header="MB-ActiveCleaner combo pack"
            imageAspect="9/16"
            description={
              <div className="h-full flex flex-col justify-between">
                3 x 1-litre concentrate + spray bottle, empty
                <div>
                  <div>Item no. 80.002</div>
                </div>
              </div>
            }
            imageUrl="https://www.urimat.com/fileadmin/produkte/reinigungsmittel/flasche_1l_u_spruehflasche.png"
            url="/products/urinals"
            title="MB-ActiveTrap eco"
          />
          <ItemCard4
            key="MB-ActiveCleaner 10-litre concentrate"
            header="MB-ActiveCleaner 10-litre concentrate"
            imageAspect="9/16"
            description={
              <div className="h-full flex flex-col justify-between">
                Unbeatable cost-effectiveness
                <div>
                  <div>Item no. 80.006</div>
                </div>
              </div>
            }
            imageUrl="https://www.urimat.com/fileadmin/user_upload/mb_reiniger_10l.png"
            url="/products/urinals"
            title="MB-ActiveTrap eco"
          />
          <ItemCard4
            key="MB-ActiveCleaner-CS"
            header="MB-ActiveCleaner-CS"
            imageAspect="9/16"
            description={
              <div className="h-full flex flex-col justify-between">
                Suitable for our cleaning system (6x0.5l)
                <div>
                  <div>Item no. 80.016</div>
                </div>
              </div>
            }
            imageUrl="https://www.urimat.com/fileadmin/produkte/reinigungsmittel/mb-activecleaner-rs-1l.png"
            url="/products/urinals"
            title="MB-ActiveTrap eco"
          />
        </div>
        <div className="flex gap-8 w-full">
          <div className="relative bg-white shadow-card w-2/3 rounded-tl-3xl rounded-br-3xl p-6 flex justify-center">
            <div className="flex justify-between">
              <div className="gap-2 flex flex-col">
                <h2 className="text-primary font-normal font-sans text-2xl card-title">
                  Reduce your impact
                </h2>
                <p className="font-light">
                  You save money and the environment will thank you Uses up to
                  one hundred times less energy than paper and cloth dispensers.
                  Play an active role in protecting the environment with URIMAT
                  Hand Dryers.
                </p>
              </div>
              <div
                className=" bg-no-repeat bg-contain bg-bottom w-1/2 aspect-[16/9] h-1/2"
                style={{
                  backgroundImage: `url(https://www.urimat.com/fileadmin/produkte/reinigungsmittel/mb-activecleaner_biological_restroom_cleaner.png)`,
                }}
              />
            </div>
            <Link
              to="https://www.urimat.com/en/services/downloads"
              className="absolute -bottom-7 rounded-tr-3xl rounded-bl-3xl hover:cursor-pointer hover:scale-105 text-white font-thin text-base gap-2 flex justify-center items-center w-[60%] h-14 bg-primary uppercase"
            >
              All Downloads
            </Link>
          </div>
          <div className="bg-white shadow-card relative w-1/3 rounded-tl-3xl rounded-br-3xl p-6 pb-20 flex flex-col items-center">
            <div className="flex w-full gap-4">
              <div
                className=" bg-no-repeat bg-contain bg-bottom w-1/4 aspect-[1/1] h-40"
                style={{
                  backgroundImage: `url(https://www.urimat.com/fileadmin/_processed_/b/c/csm_broschuere-handdryer-2020-en_da40bebc3e.png)`,
                }}
              />
              <div className="gap-2 ">
                <h2 className="text-primary font-normal font-sans text-2xl card-title">
                  Documents
                </h2>
                <p className="font-light">Product Brochure URIMAT hand dryer</p>
              </div>
            </div>
            <div className="flex w-full gap-4">
              <div
                className=" bg-no-repeat bg-contain bg-bottom w-1/4 aspect-[1/1] h-40"
                style={{
                  backgroundImage: `url(https://www.urimat.com/fileadmin/_processed_/b/c/csm_broschuere-handdryer-2020-en_da40bebc3e.png)`,
                }}
              />
              <div className="gap-2 ">
                <h2 className="text-primary font-normal font-sans text-2xl card-title">
                  Documents
                </h2>
                <p className="font-light">Product Brochure URIMAT hand dryer</p>
              </div>
            </div>
            <div className="flex w-full gap-4">
              <div
                className=" bg-no-repeat bg-contain bg-bottom w-1/4 aspect-[1/1] h-40"
                style={{
                  backgroundImage: `url(https://www.urimat.com/fileadmin/_processed_/b/c/csm_broschuere-handdryer-2020-en_da40bebc3e.png)`,
                }}
              />
              <div className="gap-2 ">
                <h2 className="text-primary font-normal font-sans text-2xl card-title">
                  Documents
                </h2>
                <p className="font-light">Product Brochure URIMAT hand dryer</p>
              </div>
            </div>
            <Link
              to="https://www.urimat.com/en/services/downloads"
              className="rounded-tr-3xl rounded-bl-3xl hover:cursor-pointer hover:scale-105 text-white font-thin text-base gap-2 absolute -bottom-7 flex justify-center items-center w-[60%] h-14 bg-primary uppercase"
            >
              All Downloads
            </Link>
          </div>
        </div>
        <div className="flex flex-col gap-8">
          <div className="text-primary text-3xl font-light ">
            What is biological cleaning?
          </div>
          <div className="text-base text-txtTT flex gap-4">
            <div className="w-2/3 list-decimal font-extralight">
              Most sanitary cleaners on the market have a list of chemicals with
              warnings on the bottles. These "aggressive" ingredients can
              negatively affect your health and also damage surfaces. With
              biological cleaners, you know that the bacteria this cleaner
              contains are the same as the bacteria that created and supports
              life. Biological cleaning products are completely natural, safe
              for your home, environmentally friendly and have the ability to
              clean right into the microscopic pores of surfaces. As all the
              ingredients in the biological cleaner are of natural origin, there
              are no negative effects on your health.
            </div>
            <div
              className="w-1/3 bg-no-repeat bg-contain bg-bottom aspect-[16/9]"
              style={{
                backgroundImage: `url(https://www.urimat.com/fileadmin/user_upload/reinigungsfachfrau_in_oeffentlicher_toilette_istock.png)`,
              }}
            />
          </div>
        </div>
        <div className="flex w-full flex-col gap-4">
          <div className="text-primary text-3xl font-light ">
            Biological sanitary cleaner - How it works
          </div>
          <div className="text-txtTT font-extralight">
            URIMAT's biological cleaning products contain selected types of
            bacterial strains that can remain inactive for a long time as
            so-called spores. They are reactivated during cleaning and then do
            the cleaning work for you. They continue to work for hours after
            application to displace other harmful bacteria that may have gotten
            onto a surface. By quickly consuming the food (dirt) that the
            harmful bacteria need to survive, they help keep your surfaces
            hygienic long after cleaning.
          </div>
        </div>
      </div>
      <CoreFooter />
    </div>
  );
}
