import React from "react";
import { CoreFooter } from "../../components/Footer";

export default function AboutUsOverview(props) {
  return (
    <div className="bg-white w-full h-full flex flex-col items-center overflow-y-auto">
      <div
        className="w-full min-h-[65%] bg-cover bg-center bg-no-repeat overflow-y-auto flex flex-col justify-end items-start p-16 px-[20%] text-white gap-8"
        style={{
          backgroundImage: `linear-gradient( hsla(202, 100%, 30%, 0.8), hsla(202, 100%, 35%, 0.8) ),
          url("https://www.urimat.com/fileadmin/_processed_/d/f/csm_gletscher-header-1920x1080_a0fa328b2f.jpg")`,
        }}
      >
        <div className="w-1/3 text-3xl">
          The company that produces environmentally friendly, economical
          solutions.
        </div>
        <div className="w-full flex">
          <div className="w-1/3 h-2/3 font-medium text-3xl text-center">
            100,000 l<div className="font-normal">Savings per urinal </div>
            <div className="font-light">
              At least 100,000 litres of drinking water per urinal are saved
              each year.
            </div>
          </div>
          <div className="w-1/3 h-2/3 font-medium text-3xl text-center">
            2,617 CHF<div className="font-normal">Cost benefit</div>
            <div className="font-light">
              The annual cost benefit if you use a URIMAT hand dryer instead of
              towels.
            </div>
          </div>
          <div className="w-1/3 h-2/3 font-medium text-3xl text-center">
            350 g
            <div className="font-normal">
              CO<sub>2</sub> reduction
            </div>
            <div className="font-light">
              Approximately 350 g of CO<sub>2</sub> are saved per cubic metre of
              water.
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex flex-col items-center justify-center my-16">
        <div className="flex flex-col w-[60%] items-center  top-12 gap-16">
          <div className="flex justify-center px-6 gap-6 text-secondary text-3xl">
            <div className="font-light flex flex-col gap-4 w-1/2">
              <div className="text-primary text-5xl">Welcome to URIMAT</div>
              <div className="text-txtTT">
                URIMAT is one of the leading producers of environmentally
                friendly waterless urinals.
              </div>
            </div>
            <div className=" w-1/2 text-xl font-light text-txtTT">
              Waterless urinals from the Swiss manufacturer URIMAT have become
              widely accepted and ensure good hygiene and environmental
              protection in thousands of men’s toilets around the world. Thanks
              to the innovative technology and high value retention of our
              products, new customers are turning to our solution every day and
              actively helping to save millions of litres of clean drinking
              water. URIMAT is committed to environmental sustainability. Our
              products are produced in accordance with environmentally friendly
              practices and materials. In choosing our partners, we look at
              carbon-neutral production and ethical principles. Set an example
              today. Turn to waterless urinals from URIMAT.
            </div>
          </div>
          <div className="w-full flex justify-center px-6 gap-6 text-secondary text-3xl">
            <div
              className="w-1/2 bg-contain bg-no-repeat bg-center aspect-[1/1]"
              style={{
                backgroundImage:
                  "url(https://www.urimat.com/fileadmin/_processed_/f/b/csm_urimat-oekologie-umweltkreislauf_963ae67b3b.png)",
              }}
            />
            <div className="font-light flex flex-col gap-4 w-1/2">
              <div className="text-primary text-4xl">Welcome to URIMAT</div>
              <ul className="text-txtTT text-xl font-light gap-3 list-decimal list-inside">
                <li>Environmentally friendly</li>
                <li>Cost-effective</li>
                <li>Quick and efficient</li>
                <li>Simple installation</li>
              </ul>
            </div>
          </div>
          <div className="w-full flex gap-6 row-3">
            <div className="bg-white shadow-card w-full rounded-tl-3xl rounded-br-3xl p-6 gap-8 flex flex-col justify-between items-center">
              <div className="flex flex-col text-txtTT gap-4 justify-between ">
                <h2 className="font-normal font-sans text-2xl card-title">
                  Efficient green solution
                </h2>
                <div className="font-light h-2/3">
                  URIMAT is part of the Solar Impulse Foundation 1000 solutions
                  that are clean, profitable & protect the environment.
                </div>
              </div>
              <div
                className={`bg-no-repeat bg-contain bg-bottom w-[60%] aspect-[1/1]`}
                style={{
                  backgroundImage: `url(https://www.urimat.com/fileadmin/_processed_/e/a/csm_sif_label_logo_institutional_2020_rvb_a6a7b5af58.png)`,
                }}
              />
            </div>
            <div className="bg-white shadow-card w-full rounded-tl-3xl rounded-br-3xl p-6 gap-8 flex flex-col justify-between items-center">
              <div className="flex flex-col text-txtTT gap-4 justify-between ">
                <h2 className="font-normal font-sans text-2xl card-title">
                  Efficient green solution
                </h2>
                <div className="font-light h-2/3">
                  URIMAT is part of the Solar Impulse Foundation 1000 solutions
                  that are clean, profitable & protect the environment.
                </div>
              </div>
              <div
                className={`bg-no-repeat bg-contain bg-bottom w-[60%] aspect-[1/1]`}
                style={{
                  backgroundImage: `url(https://www.urimat.com/fileadmin/_processed_/5/c/csm_urimat_un_sdg_goals_a1e7a04848.png)`,
                }}
              />
            </div>
            <div className="bg-white shadow-card w-full rounded-tl-3xl rounded-br-3xl p-6 gap-8 flex flex-col justify-between items-center">
              <div className="flex flex-col text-txtTT gap-4 justify-between ">
                <h2 className="font-normal font-sans text-2xl card-title">
                  Efficient green solution
                </h2>
                <div className="font-light h-2/3">
                  URIMAT is part of the Solar Impulse Foundation 1000 solutions
                  that are clean, profitable & protect the environment.
                </div>
              </div>
              <div
                className={`bg-no-repeat bg-contain bg-bottom w-[60%] aspect-[1/1]`}
                style={{
                  backgroundImage: `url(https://www.urimat.com/fileadmin/_processed_/9/0/csm_u.s._green_building_council_logo.svg_0b9e58e153.png)`,
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <CoreFooter />
    </div>
  );
}
