import React from "react";
import { ItemCard4 } from "../../components/Card";
import { Link } from "react-router-dom";
import { CoreFooter } from "../../components/Footer";

export default function MBActiveTrap(props) {
  return (
    <div className="bg-white w-full h-full flex flex-col items-center overflow-y-auto">
      <div
        className="w-full min-h-[65%] bg-gray-200 bg-contain bg-left bg-no-repeat p-6 overflow-y-auto flex justify-around items-center"
        style={{
          backgroundImage: `url("https://www.urimat.com/fileadmin/produkte/activetrap/header-mb-activetrap.png")`,
        }}
      >
        <div className="w-2/6"></div>
        <div className="w-2/6 flex flex-col gap-4">
          <div className="text-primary text-5xl font-bold">MB-ActiveTrap</div>
          <div className="text-3xl text-txtSecondary font-extralight">
            The odour trap with membrane technology improves safety and
            reliability in sanitary facilities.
          </div>
          <ul className="text-txtTT flex flex-col gap-3 list-disc list-inside">
            <li>Environmentally friendly</li>
            <li>Cost-effective</li>
            <li>Quick and efficient</li>
            <li>Simple installation</li>
            <li>Robust & durable</li>
          </ul>
          <Link
            to="/products/urinals"
            className="mt-3 rounded-tr-3xl rounded-bl-3xl hover:cursor-pointer hover:scale-105 text-white font-thin text-xl flex justify-center items-center w-[50%] h-14 bg-primary"
          >
            Replacement Instructions
          </Link>
        </div>
      </div>
      <div className="w-[60%] flex flex-col items-center justify-center top-12 gap-16 my-16">
        <div className="flex justify-center px-6 gap-6">
          <div className="text-txtTT font-light w-1/4">
            <div className=" font-normal">New design</div>
            You will love our URIMAT hand dryers and their attractive design.
          </div>
          <div className="text-txtTT font-light w-1/4">
            <div className=" font-normal">
              Low-cost and environmentally friendly
            </div>
            Our hand dryers not only protect the environment, but also help to
            save costs. No storage, no disposal of paper and fabric towels. The
            energy requirements of our hand dryer are significantly lower than
            those of paper and fabric towel dispensers.
          </div>
          <div className="text-txtTT font-light w-1/4">
            <div className=" font-normal">Absolute hygiene </div>
            With their contactless, sensor-controlled operation, our hand dryers
            are based on a clean technology.
          </div>
          <div className="text-txtTT font-light w-1/4">
            <div className=" font-normal">Engine</div>
            With the brushless electric motor, there are no brushes (sliding
            contacts), hence there is no mechanical wear. This type of motor
            lasts significantly longer. Step regulation and hot and cold air
            operation are possible.
          </div>
        </div>
        <div className="flex row-span-3 gap-8">
          <ItemCard4
            key="URIMAT eco"
            header="URIMAT eco"
            imageAspect="16/9"
            description={
              <div className="h-full flex flex-col justify-between">
                Suitable for all plastic URIMAT bowls
                <div>
                  <div>Item no.: 52102 (box of 2)</div>
                  <div>Item no.: 52102 (box of 2)</div>
                </div>
              </div>
            }
            imageUrl="https://www.urimat.com/fileadmin/produkte/activetrap/urimat-activetrap-eco-compact.png"
            url="/products/urinals"
            title="MB-ActiveTrap eco"
          />
          <ItemCard4
            key="URIMAT ecoplus"
            header="URIMAT ecoplus"
            imageAspect="16/9"
            description={
              <div className="h-full flex flex-col justify-between">
                <div>
                  Suitable for all ceramic and stainless steel URIMAT bowls.
                </div>
                <div>
                  <div>Item no.: 52102 (box of 2)</div>
                  <div>Item no.: 52102 (box of 2)</div>
                </div>
              </div>
            }
            imageUrl="https://www.urimat.com/fileadmin/produkte/activetrap/urimat-activetrap-keramik-edelstahl.png"
            url="/products/urinals"
            title="MB-ActiveTrap ceramic"
          />
          <ItemCard4
            key="URIMAT ecovideo"
            header="URIMAT ecovideo"
            imageAspect="16/9"
            description={
              <div className="h-full flex flex-col justify-between">
                Suitable for all URIMAT bowls with a cleaning system.
                <div>
                  <div>Item no.: 52102 (box of 2)</div>
                  <div>Item no.: 52102 (box of 2)</div>
                </div>
              </div>
            }
            imageUrl="https://www.urimat.com/fileadmin/user_upload/trap-cs-web.png"
            url="/products/urinals"
            title="MB-ActiveTrap-CS"
          />
          <ItemCard4
            key="URIMAT ecovideo"
            header="URIMAT ecovideo"
            imageAspect="16/9"
            description={
              <div className="h-full flex flex-col justify-between">
                Suitable for different waterless urinals bowls from outlet
                diameter 38mm up to 136mm.
                <div>IPlease contact us for more information.</div>
              </div>
            }
            imageUrl="https://www.urimat.com/fileadmin/user_upload/trap-mitbewerber-2.png"
            url="/products/urinals"
            title="MB-ActiveTrap"
          />
        </div>
        <div className="flex gap-8 w-full">
          {/* <div className="bg-white shadow-card w-1/2 rounded-tl-3xl rounded-br-3xl p-6 flex flex-col justify-betweenitems-center">
              <div className="gap-2 flex flex-col">
                <h2 className="text-primary font-normal font-sans text-2xl card-title">
                  Reduce your impact
                </h2>
                <p className="font-light">
                  You save money and the environment will thank you Uses up to
                  one hundred times less energy than paper and cloth dispensers.
                  Play an active role in protecting the environment with URIMAT
                  Hand Dryers.
                </p>
              </div>
              <div
                className=" bg-no-repeat bg-contain bg-bottom w-full aspect-[16/9]"
                style={{
                  backgroundImage: `url(https://www.urimat.com/fileadmin/_processed_/2/a/csm_hd-en_16e91abeac.png)`,
                }}
              />
            </div> */}

          <div className="flex flex-col gap-8 w-2/3">
            <div className="text-primary text-3xl font-light ">
              The technology of waterless urinals
            </div>
            <div className="text-base text-txtTT flex flex-col gap-4">
              <div className="text-base text-txtTT flex gap-4">
                <div className="list-decimal font-extralight w-1/2">
                  Draining urine is fed through the funnel into the odour trap
                  of the waterless urinal and then runs through the vertical
                  membrane into the sewer system.
                </div>
                <div className="list-decimal font-extralight w-1/2">
                  When there is no more urine flowing, the membrane closes and
                  thus prevents any sewer odours from escaping.
                </div>
              </div>
            </div>
            <iframe
              width="730"
              height="411"
              src="https://www.youtube.com/embed/iHIkdVUL2Oc"
              title="URIMAT MB-ActiveTrap, product description"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            />
          </div>
          <div className="bg-white shadow-card relative w-1/3 rounded-tl-3xl rounded-br-3xl p-6 flex justify-center pb-20">
            <div className="flex w-full gap-4">
              <div
                className=" bg-no-repeat bg-contain bg-bottom w-1/4 aspect-[1/1] h-40"
                style={{
                  backgroundImage: `url(https://www.urimat.com/fileadmin/_processed_/b/c/csm_broschuere-handdryer-2020-en_da40bebc3e.png)`,
                }}
              />
              <div className="gap-2 ">
                <h2 className="text-primary font-normal font-sans text-2xl card-title">
                  Documents
                </h2>
                <p className="font-light">Product Brochure URIMAT hand dryer</p>
              </div>
            </div>
            <Link
              to="https://www.urimat.com/en/services/downloads"
              className="rounded-tr-3xl rounded-bl-3xl hover:cursor-pointer hover:scale-105 text-white font-thin text-base gap-2 absolute -bottom-7 flex justify-center items-center w-[60%] h-14 bg-primary uppercase"
            >
              All Downloads
            </Link>
          </div>
        </div>
        <div className="flex flex-col gap-8">
          <div className="text-primary text-3xl font-light ">
            Odour trap for waterless urinals
          </div>
          <div className="text-base text-txtTT flex flex-col gap-4">
            <div className="w-full list-decimal font-extralight">
              Our technology has proven itself 100,000 times over. Use the
              efficient MB-ActiveTrap also in combination with urinals from
              other manufacturers. The odour trap with membrane technology
              brings more safety and reliability to the sanitary area. The
              MB-ActiveTrap absorbs urine and directs it into the sewer system
              without flushing the drainage system and prevents odours from
              escaping, even under varying pressure conditions. The liquid is
              led directly into the sewer system via a vertically suspended
              membrane (membrane odour trap). Above the membrane is a
              microbiological cleaning cube, which reduces deposits and
              encrustations inside the odour trap, as well as in the drain pipe.
              As soon as no more urine passes through the membrane, it closes
              airtight. After the integrated cleaning cube has been removed, the
              red contact surface under the transparent cover indicates that the
              waterless urinal's odour trap needs to be replaced. Another
              advantage is the longevity of the odour traps. They only need to
              be replaced after 7000-8000 uses. This helps to keep the costs and
              maintenance effort very low. Changing the traps is also very easy.
              With the blue hand hook, the traps can be replaced with a flick of
              the wrist and without any touching
            </div>
          </div>
        </div>
        <div className="flex w-full gap-8">
          <div className="w-1/2 flex flex-col gap-6 p-6 ">
            <div
              className=" bg-no-repeat bg-contain bg-center mx-auto w-2/3 aspect-[1/1] "
              style={{
                backgroundImage: `url(https://www.urimat.com/fileadmin/_processed_/c/6/csm_trap_neu_24f391d033.jpg)`,
              }}
            />
            New MB-ActiveTrap with cleaning cube
          </div>
          <div className="w-1/2 flex flex-col gap-6 p-6 ">
            <div
              className=" bg-no-repeat bg-contain bg-center mx-auto w-2/3 aspect-[1/1] "
              style={{
                backgroundImage: `url(https://www.urimat.com/fileadmin/_processed_/9/0/csm_trap_verbraucht_f8a975c1cd.jpg)`,
              }}
            />
            The cube of the MB-ActiveTrap is almost consumed and must be
            replaced soon
          </div>
        </div>
      </div>
      <CoreFooter />
    </div>
  );
}
