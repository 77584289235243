import React, { useState } from "react";
import { ItemCard3 } from "../../components/Card";
import { CoreFooter } from "../../components/Footer";
import { Link } from "react-router-dom";
export default function HandDryers(props) {
  const [num, setNum] = useState(5);
  React.useEffect(() => {
    function handleResize() {
      setNum(Math.floor(window.innerWidth / "330"));
    }
    window.addEventListener("resize", handleResize);
  });
  return (
    <div className="bg-white w-full h-full flex flex-col items-center overflow-y-auto">
      <div
        className="w-full min-h-[65%] bg-gray-200 bg-contain bg-left bg-no-repeat p-6 overflow-y-auto flex justify-around items-center"
        style={{
          backgroundImage: `url("https://www.urimat.com/fileadmin/user_upload/header-handdryer2.png")`,
        }}
      >
        <div className="w-2/6"></div>
        <div className="w-2/6 flex flex-col gap-4">
          <div className="text-primary text-5xl font-bold">Hand dryers</div>
          <div className="text-3xl text-txtSecondary font-extralight">
            Dry your hands in 9-11 seconds. Quiet, fast and environmentally
            friendly.
          </div>
          <ul className="text-txtTT flex flex-col gap-3 list-disc list-inside">
            <li>Environmentally friendly</li>
            <li>Cost-effective</li>
            <li>Quick and efficient</li>
            <li>Simple installation</li>
            <li>Robust & durable</li>
          </ul>
        </div>
      </div>
      <div className="w-[60%] flex flex-col items-center justify-center top-12 gap-16 my-16">
          <div className="flex justify-center px-6 gap-6">
            <div className="text-txtTT font-light w-1/4">
              <div className=" font-normal">New design</div>
              You will love our URIMAT hand dryers and their attractive design.
            </div>
            <div className="text-txtTT font-light w-1/4">
              <div className=" font-normal">
                Low-cost and environmentally friendly
              </div>
              Our hand dryers not only protect the environment, but also help to
              save costs. No storage, no disposal of paper and fabric towels.
              The energy requirements of our hand dryer are significantly lower
              than those of paper and fabric towel dispensers.
            </div>
            <div className="text-txtTT font-light w-1/4">
              <div className=" font-normal">Absolute hygiene </div>
              With their contactless, sensor-controlled operation, our hand
              dryers are based on a clean technology.
            </div>
            <div className="text-txtTT font-light w-1/4">
              <div className=" font-normal">Engine</div>
              With the brushless electric motor, there are no brushes (sliding
              contacts), hence there is no mechanical wear. This type of motor
              lasts significantly longer. Step regulation and hot and cold air
              operation are possible.
            </div>
          </div>
          <div className="flex row-span-3 gap-8">
            <ItemCard3
              key="URIMAT eco"
              header="URIMAT eco"
              description="Hightech-plastic waterless urinal"
              imageUrl="https://www.urimat.com/fileadmin/_processed_/0/1/csm_urimat-ecoplus-klein_895f6a8b49.png"
              url="/products/urinals"
              title="Further Information"
            />
            <ItemCard3
              key="URIMAT ecoplus"
              header="URIMAT ecoplus"
              description="Hightech-plastic waterless urinal with advertising display"
              imageUrl="https://www.urimat.com/fileadmin/_processed_/0/1/csm_urimat-ecoplus-klein_895f6a8b49.png"
              url="/products/urinals"
              title="Further Information"
            />
            <ItemCard3
              key="URIMAT ecovideo"
              header="URIMAT ecovideo"
              description="Hightech-plastic waterless urinal with LCD video display"
              imageUrl="https://www.urimat.com/fileadmin/_processed_/0/1/csm_urimat-ecoplus-klein_895f6a8b49.png"
              url="/products/urinals"
              title="Further Information"
            />
          </div>
          <div className="flex gap-8 w-full">
            <div className="bg-white shadow-card w-1/2 rounded-tl-3xl rounded-br-3xl p-6 flex flex-col justify-betweenitems-center">
              <div className="gap-2 flex flex-col">
                <h2 className="text-primary font-normal font-sans text-2xl card-title">
                  Reduce your impact
                </h2>
                <p className="font-light">
                  You save money and the environment will thank you Uses up to
                  one hundred times less energy than paper and cloth dispensers.
                  Play an active role in protecting the environment with URIMAT
                  Hand Dryers.
                </p>
              </div>
              <div
                className=" bg-no-repeat bg-contain bg-bottom w-full aspect-[16/9]"
                style={{
                  backgroundImage: `url(https://www.urimat.com/fileadmin/_processed_/2/a/csm_hd-en_16e91abeac.png)`,
                }}
              />
            </div>

            <div className="bg-white shadow-card relative w-1/2 rounded-tl-3xl rounded-br-3xl p-6 flex justify-center pb-20">
              <div className="flex w-full gap-4">
                <div
                  className=" bg-no-repeat bg-contain bg-bottom w-1/4 aspect-[1/1] h-40"
                  style={{
                    backgroundImage: `url(https://www.urimat.com/fileadmin/_processed_/b/c/csm_broschuere-handdryer-2020-en_da40bebc3e.png)`,
                  }}
                />
                <div className="gap-2 ">
                  <h2 className="text-primary font-normal font-sans text-2xl card-title">
                    Documents
                  </h2>
                  <p className="font-light">
                    Product Brochure URIMAT hand dryer
                  </p>
                </div>
              </div>
              <Link
                to="https://www.urimat.com/en/services/downloads"
                className="rounded-tr-3xl rounded-bl-3xl hover:cursor-pointer hover:scale-105 text-white font-thin text-base gap-2 absolute -bottom-7 flex justify-center items-center w-[60%] h-14 bg-primary uppercase"
              >
                All Downloads
              </Link>
            </div>
          </div>
          <div className="flex flex-col gap-8">
            <div className="text-primary text-3xl font-light ">
              The technology of waterless urinals
            </div>
            <div className="text-base text-txtTT flex flex-col gap-4">
              <div className="w-full list-decimal font-extralight">
                A hand dryer is an electrical device for drying hands in the
                sanitary room by means of a generated cold or warm air flow. The
                integrated motor draws in the ambient air via a blower and then
                blows the heated air back via a germ filter (HEPA filter) into
                the air duct for drying hands. The function is triggered by a
                sensor to which you hold your hands. The warm air that flows out
                of the hand dryer ensures dry hands within a few seconds by
                simply evaporating the moisture - without the use of paper or
                cloth towels.
              </div>
            </div>
          </div>
          <div className="flex w-full gap-8">
            <div className="flex flex-col w-7/9 gap-4">
              <div className="text-primary text-3xl font-light ">
                What should I look for in a hand dryer?
              </div>
              <ul className="text-txtTT font-extralight flex flex-col gap-3 list-disc list-inside">
                <li>
                  <span className=" font-light">Drying time:</span> A good hand
                  dryer should work quickly and effectively and dry the hands
                  within a very short time (9-11 s). A fast drying time reduces
                  the waiting time for other toilet users.
                </li>
                <li>
                  <span className=" font-light">
                    Easy cleaning of the hand dryer:
                  </span>
                  In order to avoid bacteria and germs when drying hands with a
                  hand dryer or with the hand dryer itself, the dryer should be
                  easy to clean, i.e. the HEPA filter and the water collection
                  tray are integrated in the device and easy to change or clean.
                  Touchless devices with a sensor are more hygienic than devices
                  where the drying process has to be started with a
                  switch/button.
                </li>
                <li>
                  <span className=" font-light">Loudness: </span>
                  {`A hand dryer must not become a noise nuisance, as too loud devices with a lot of power are quickly perceived as very annoying. A noise level of <80 dB during use is to be aimed for. With some hand dryer models, the air speed and thus the noise level can be individually adjusted between two levels with a switch. `}
                </li>
              </ul>
            </div>

            <div
              className=" bg-no-repeat bg-contain bg-center aspect-[1/1] w-2/9"
              style={{
                backgroundImage: `url(https://www.urimat.com/fileadmin/_processed_/d/a/csm_urimat_handdryer_favorit_4_1e15a912d1.png)`,
              }}
            />
          </div>
          <div className="flex flex-col gap-8">
            <div className="text-primary text-3xl font-light ">
              How hygienic is a hand dryer?
            </div>
            <div className="text-base text-txtTT flex flex-col gap-4">
              <div className="w-full list-decimal font-extralight">
                {`Washing hands after using the toilet is essential for daily hand
                hygiene. But washing alone is not enough. Hands should be dried
                carefully after cleaning. A hand dryer with a HEPA filter
                ensures that the air drawn in is cleaned of >99.9% of all dirt
                particles and germs. These filters are also used, for example,
                in operating theatres, in aircrafts, etc. to keep the air clean.
                Thus, a hand dryer with a HEPA filter ensures hygienic hand
                drying.`}
              </div>
            </div>
          </div>
        </div>
      <CoreFooter />
    </div>
  );
}
